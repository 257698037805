import { useLazyQuery, useResult } from '@vue/apollo-composable';
import historyListScheme from '@/modules/controls-history/api/historyList.graphql';
import linkedObjectScheme from '@/modules/linked-objects/api/linked-object.graphql';
import { genListLoader } from '@/utils/list-generators';
import { ref } from '@vue/composition-api';
import historyItems from '@/utils/list-generators/history/historyItems';

export function useHistoryList({ ids, fromDate, toDate, monitoringItem }) {
  const isLoadingVirtualList = ref(0); // 0: No loading, 1: Loading, 2: Done
  const variables = ref({
    first: 30,
    after: null,
    filter: {
      type: {
        equalTo: 'RPC'
      },
      objectId: {
        equalTo: monitoringItem.id
      },
      createdAt: {
        greaterThanOrEqualTo: fromDate,
        lessThanOrEqualTo: toDate
      }
    }
  });

  if (monitoringItem.controlName) {
    variables.value.filter.name = {
      equalTo: monitoringItem.controlName
    };
  }

  if (!fromDate) {
    const today = new Date();
    variables.value.filter.createdAt.greaterThanOrEqualTo = new Date(
      today - 1
    ).toISOString();
  }

  if (!toDate) {
    variables.value.filter.createdAt.lessThanOrEqualTo = new Date().toISOString();
  }
  const { result, load, onResult, restart, refetch } = useLazyQuery(
    historyListScheme.fetchHistoryControlsListConnection,
    variables,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network'
    }
  );

  const objectSchemaControlsDescription = useLazyQuery(
    linkedObjectScheme.fetch,
    {
      id: monitoringItem.id
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network'
    }
  );
  const pageInfo = useResult(
    result,
    {
      endCursor: null,
      hasNextPage: true
    },
    data => {
      return data.controlExecutionsConnection.pageInfo;
    }
  );
  const loadMore = () => {
    isLoadingVirtualList.value = 1;
    if (!result.value) {
      load();
    } else {
      if (pageInfo.value.hasNextPage) {
        variables.value.after = pageInfo.value.endCursor;
      } else {
        isLoadingVirtualList.value = 2;
      }
    }

    onResult(() => {
      isLoadingVirtualList.value = 0;
    });
  };

  const getDescriptionForControl = item => {
    return (
      schemaControlDescriptions.value.find(
        itemInner => itemInner.rpc === item.node.name
      )?.description || `${item.node.name} (No description provided)`
    );
  };

  const historyList = useResult(result, [], data => {
    return [
      ...data.controlExecutionsConnection.edges.map(item =>
        historyItems[monitoringItem.type](
          { node: { ...item.node, name: getDescriptionForControl(item) } },
          data.controlExecutionsConnection
        )
      ),
      genListLoader({ isLoading: isLoadingVirtualList })
    ];
  });

  const points = useResult(result, [], data => {
    return data.objectPropertiesHistories.map(item => [
      item.value?.lon,
      item.value?.lat
    ]);
  });

  const schemaControlDescriptions = useResult(
    objectSchemaControlsDescription.result,
    [],
    data => {
      return data.object.schema.schemaControls;
    }
  );

  return {
    load,
    restart,
    historyList,
    points,
    variables,
    pageInfo,
    loadMore,
    result,
    refetch,
    loadDescriptions: objectSchemaControlsDescription.load,
    loadDescriptionsOnResult: objectSchemaControlsDescription.onResult
  };
}
