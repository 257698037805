<template>
  <history-list-card
    :title="monitoringItem.name || 'History'"
    :items="historyList"
    :id-controls="ids"
    :object-id="monitoringItem.id"
    :bench="bench"
    infinite
    @infinite="loadMore"
    @click:back="handleBack"
    @onRangeChanged="handleRangeChanged"
  />
</template>

<script>
import { onBeforeUnmount, onMounted, watch } from '@vue/composition-api';
import { useHistoryList } from '@/modules/controls-history/compositions/historyList';
import { useMap } from '@/compositions/map';
import {promisifyQuery} from "@/utils";

export default {
  name: 'HistoryControlsList',
  props: {
    propertyId: {
      type: String,
      default: ''
    },
    fromDate: {
      type: String,
      default: ''
    },
    toDate: {
      type: String,
      default: ''
    },
    monitoringItem: {
      type: Object,
      default: () => ({})
    },
    ids: {
      type: Array,
      default: () => []
    },
    bench: {
      type: [Number, String],
      default: 1
    }
  },
  setup(props, { emit, root }) {
    const {
      historyList,
      pageInfo,
      load,
      points,
      variables,
      loadMore,
      loadDescriptionsOnResult,
      loadDescriptions
    } = useHistoryList({
      ids: props.ids,
      fromDate: props.fromDate,
      toDate: props.toDate,
      monitoringItem: props.monitoringItem,
      objectId: root.$route.params.objectId
    });

    loadDescriptionsOnResult(() => {
      load();
    });

    const {
      markers: { setMarkers, selectMarker },
      track: { setTrack, clearTrack, setMapToTrackCenter }
    } = useMap();

    const { objectId } = root.$route.params;

    const handleBack = () => {
      emit('stack:back');
    };

    const handleRangeChanged = fromDate => {
      variables.value.filter.createdAt.greaterThanOrEqualTo = fromDate;
    };

    onMounted(async () => {
      await promisifyQuery(loadDescriptions, loadDescriptionsOnResult);
      load();
    });

    onBeforeUnmount(() => {
      clearTrack();
    });

    watch(
      () => points.value,
      () => {
        if (points.value.length) {
          setTrack(points.value);
          setMapToTrackCenter();
        }
      }
    );

    return {
      pageInfo,
      loadMore,
      historyList,
      handleBack,
      handleRangeChanged
    };
  }
};
</script>

<style></style>
